html {
  width:100%;
  height:100%;
  overflow: hidden;
  font-family: "Roboto" !important;
}

.hidden {
  overflow: hidden !important;
  width:0px !important;
  height:0px !important;
}

.slot-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
}

.slot-body {
  font-size:11px;
}

.login-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:100%;
}

.login-box {
  width:300px;
  height:400px;
  background-color: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

body {
  width:100%;
  height:100%;
}

#root {
  width:100%;
  height:100%;
}

.App {
  display: flex;
  width:100%;
  height:100%;
  justify-content:center;
  align-items: center;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.transparent-bg {
  width:100%;
  height:100%;
  background-color:rgba(0,0,0,0);
  display:flex;
  justify-content: center;
  align-items: center;
}

.gay-gradient {
  width: 100%;
  height: 100%;
  color: #fff;
  background: linear-gradient(45deg, #e134eb, #8f3a94, #fa9eff, rgb(178, 57, 248));
  background-size: 400% 400%;
  -webkit-animation: Gradient 9s ease infinite;
  -moz-animation: Gradient 9s ease infinite;
  animation: Gradient 9s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  }

.moving-gradient {
  width: 100%;
  height: 100%;
  color: #fff;
  background: linear-gradient(45deg, #fff, #000, #149644, rgb(178, 57, 248));
  background-size: 400% 400%;
  -webkit-animation: Gradient 9s ease infinite;
  -moz-animation: Gradient 9s ease infinite;
  animation: Gradient 9s ease infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  }

.metric:hover {
  animation: MetricSize 500ms ease forwards;
}

@keyframes MetricSize {
  0% {
    transform: scale(1.0);
    background-color: rgba(0,0,0,0);
  }
  100% {
    transform: scale(1.3);
    background-color: rgba(0,0,0,0.5);
  }
}

.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.calendar {
  display: flex;
  flex: 1;
  max-height: 1000px;
  background-color: rgba(255,255,255,0.9);
}

.box {
  background-color: rgba(255,255,255,0.7);
  border-radius: 5px;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  max-height: 80vh;
  max-width:78vw;
}

@keyframes BigBox {
  0% {
    max-width:78vw;
  }
  100% {
    max-width: 89vw;
  }
}

.box.menu_is_hidden {
  animation: BigBox 500ms ease forwards;
}

.box.menu_is_showing {
  animation: BigBox 500ms easy reverse;
}

.calendar-table tr {
  display: flex;
}

.calendar-heading {
  color:#FFF;
  display: flex;
  justify-content: center;
  margin:3px;
  border-radius: 3px;
  -webkit-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);

}

.calendar-side-left {
  width: 200px;
  min-width: 200px
}

.calendar-main {
  overflow: scroll;
  height:100%;
}

.heading-now,#today .date-col-cell-inner {
  background-color: #119100;
}

.heading-past {
  background-color: #163757;
}

.heading-future {
  background-color: #6c6a6a;
}

.day-column {
  width:120px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  left:0px;
  box-sizing: border-box;
  border-width: 0px;
  box-shadow: none !important;
}

.date-col-cell {
  color:#000;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px rgb(223, 223, 223);
  border-right: solid 1px rgb(223, 223, 223);
  padding: 3px;
  z-index: 600;
  display:flex;
}

.date-col-cell-inner {
  border-radius: 3px;
  flex:1;
  -webkit-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  font-weight: bold;
  color:#fff;
  text-shadow: 1px #000;
}


.left-menu {
  background-color:rgba(255,255,255,0.7);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding:8px;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 30px 1px rgba(0,0,0,0.86);
  box-sizing: border-box;
  max-width:20vw;
  margin:10px;
  flex:1;
  overflow-y:auto;
}

.container {
  width:100%;
  height:100%;
  display:flex;
  flex-direction: row;
  margin-top:80px;
}

.container-left {
  padding:2vw;
  width:12vw;
  flex-direction: column;
  display:flex;
  justify-content: center;
  align-self: stretch;
}

.notification-stack {
  display:flex;
  flex-direction: column;
  align-self: stretch;
  overflow-y: auto;
  padding:0.5vw;
}

.notification {
  background-color: rgba(255,255,255,0.9);
  border-radius: 5px;
  padding:10px;
  display:flex;
  flex-direction:column;
  margin-bottom:20px;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 30px 1px rgba(0,0,0,0.86);
}

.notification .title {
  font-weight:bold;
  color:#000;
  font-size:13px;
}

.notification .top {
  align-self: stretch;
  justify-content: space-between;
  display:flex;
  flex-direction:row;
  align-items:center;
  padding-bottom:5px;
}

.notification .text {
  color:#000;
  font-size:11px;
}

.dot {
  width:10px;
  height:10px;
  border-radius:5px;
  background-color:red;
}

.container-middle {
  flex:1;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.container-right {
  padding-top:70px;
  width: 12vw;
  flex-direction:column;
  display:flex;
  justify-content: center;
}


.welcome-message {
  font-size:30px;
  font-weight: bold;
  margin:20px;
  text-shadow: 2px 2px #000;
}



@keyframes middleLeft {
  from {width:0vw;}
  to {width:40vw;}
}

@keyframes middleRight {
  from {width:100%;}
  to {width:50%;}
}

@keyframes middleLeftBack {
  from {width:40vw;}
  to {width:0vw;}
}

@keyframes middleRightBack {
  from {width:50%;}
  to {width:100%;}
}

@keyframes pulse {
  from {transform: scale(1.0);}
  to {transform: scale(1.08);}
}


.pulsing-slot {
  animation-name: pulse;
  animation-duration: 800ms;
  scale:1.0;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.middle-left-anim {
  animation-name: middleLeft;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  width:0vw;
}

.middle-right-anim {
  animation-name: middleRight;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  width:100%;
}

.middle-left-anim-back {
  animation-name: middleLeftBack;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  width:40vw;
}

.middle-right-anim-back {
  animation-name: middleRightBack;
  animation-duration: 800ms;
  animation-fill-mode: forwards;
  width:50%;
}

.new-booking-form {
  padding:10px;
  align-self: stretch;
  border-radius: 5px;
  background-color:rgba(255,255,255,0.8);
  display:flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 30px 1px rgba(0,0,0,0.86);
  max-height:80vh;
  overflow:auto;
  position: relative;
}

.shadow {
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 30px 1px rgba(0,0,0,0.86);
}

.heading {
  font-size:20px;
  color:#000;
  margin-bottom:20px;
}

.slot-hour {
  flex:1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.slot {
  color:#000;
  background-color:#fff;
  border: solid 3px rgb(0, 145, 0);
  text-align: center;
  border-radius: 2px;
  margin:2px;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;
  padding:5px;
  -webkit-box-shadow: 3px 4px 5px 1px rgba(0,0,0,.86);
  -moz-box-shadow: 3px 4px 5px 1px rgba(0,0,0,.86);
  box-shadow: 3px 4px 5px 1px rgba(0,0,0,.86);
}

#cal table {
  table-layout: fixed;
  position: relative;
}

#cal td {
  width:113px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  border: dashed 1px #c8c8c8;;
}

#cal th {
  width:113px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  border: none;
}

#cal thead {
  position:sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top:0px;
  z-index: 999;
  border: none !important;
}

.menu-button {
  width:160px;
  margin-bottom: 20px !important;
}

#cal tr {
  position: relative;
  display: flex;
}

tr.data {
  min-height: 120px;
}

.status {
  width:80px;
  height:75px;
  padding:3px;
  margin-bottom: 10px;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: solid 1px gray;
}

.status-label {
  color:#000;
  text-align: center;
}

.other-job-form table {
  color:#000;
  font-size:13px;  
}

.other-job-form td {
  padding-left: 10px;
  padding-right: 10px;
}

.status-value {
  display:flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.comments-table {
  table-layout: fixed;
}

.form-area .comments-table td {
  width: 240px !important;
  display:inline-block;
  color:#000;
  padding-left:20px;
  padding-right:20px;
  width:auto;
}

.form-area .comments-table th {
  width: 240px !important;
  display:inline-block;
  color:#000;
  padding-left:20px;
  padding-right:20px;
}

.notification.new {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.notification .bottom {
  margin-top:5px;
  font-size: 10px;
  color:#000;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.login-error {
  font-size: 14px;
  color: red;
  margin: 15px;
  margin-left: 50px;
}

.form-area {
  align-self: stretch;
  padding:1px;
  margin-bottom: 3px;
}

.block-booking-form {
  padding:10px;
}


.action {
  cursor: pointer;
}

.main-top {
  position: absolute;
  top:10px;
  left:450px;
}

.today {
  flex-direction: row;
  display: flex;
  margin:20px;
  text-shadow: 2px 2px #000;
}

.metric {
  margin-right:30px;
  flex-direction: column;
  display:flex;
  border-radius: 5px;
  padding:5px;
}

.metric .value {
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:32px;
}

.main-left {
  max-width: 20vw;
  overflow: hidden;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 100px;
}

@keyframes HideMenu {
  0% {
    max-width: 20vw;
  }
  100% {
    max-width: 2vw;
  }
}

@keyframes vanishAnim {
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(0.0);
  }
}

.show {
  animation: vanishAnim 500ms ease reverse;
}

.vanish {
  animation: vanishAnim 500ms ease forwards;
}

.main-left.menu_is_hidden {
  animation: HideMenu 500ms ease forwards;
}

.main-left.menu_is_showing {
  animation: HideMenu 500ms ease backwards;
}

.next-up {
  background-color: rgba(255,255,255, 0.7);
  border-radius: 5px;
  padding:10px;
  padding-left:30px;
  padding-top:5px;
  padding-right:30px;
  -webkit-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  -moz-box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  box-shadow: 8px 10px 40px 1px rgba(0,0,0,0.86);
  margin:10px;
}

.next-up .title {
  font-size:16px;
  color:#000;
  margin-bottom:10px;
}

.haulier-contacts {
  color:#000;
}

#simple-menu {
  top:30px !important;
  right:30px !important;
}

.forgot-password {
  color:#000;
  cursor: pointer;
  margin-left:20px;
}

.reset-message { 
  color:green;
  margin-left:20px;
}

#today {
  font-weight:'bold';
  background-color:#c1ffb5;
}

.subheading {
  font-size:20px;
  margin-right:35px;
  color:#000;
  margin-top:20px;
}


.pause-button {
  position: absolute;
  bottom:10px;
  left:20px;
  font-size:40px;
  color:#fff;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 50px;
}

.Mui-focused .MuiInput-underline {
  background-color:green;
}

.picture {
  margin:10px;
  width:100px;
  height:100px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

@keyframes PicHover {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.86);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.86);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.86);
  }
  100% {
    -webkit-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
    -moz-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
    box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.86);
  }
}

.picture:hover {
  animation: PicHover 250ms ease forwards;
}

.modal-image {
  width:600px;
  height:600px;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.welcome-message:hover {
  transform: scale(1.1);
}

.top-slot {
  position: absolute;
  top:10px;
  left:60%;
  width:120px !important;
}

@keyframes SlotHover {
  0% {
    transform: scale(1.0);
  }
  100% {
    transform: scale(1.2);
  }
}

@keyframes MoveUp {
  0% {
    top: -25px;
    transform: scale(1.0);
  }
  100% {
    top:-45px;
    transform: scale(1.2);
  }
}

.slot:hover {
  animation: SlotHover 500ms ease forwards;
}

.slot:hover img {
  animation: MoveUp 500ms ease forwards;
}

.slot img {
  height: 50px;
  position: absolute;
  top: -25px;
  left: 45px;
}

.notification:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
}


.slot {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 8px;
  overflow: visible;
  border: none;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  color: rgba(255,255,255,1);
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  background: -webkit-linear-gradient(-135deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(210,244,146,1) 1%, rgba(124,188,10,1) 52%, rgba(124,188,10,1) 100%);
  background: -moz-linear-gradient(225deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(210,244,146,1) 1%, rgba(124,188,10,1) 52%, rgba(124,188,10,1) 100%);
  background: linear-gradient(225deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(210,244,146,1) 1%, rgba(124,188,10,1) 52%, rgba(124,188,10,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
  -webkit-box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.3) ;
  box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.3) ;
  text-shadow: 1px 1px 1px rgba(0,0,0,0.2) ;
}

.late {
  background: -webkit-linear-gradient(-135deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(186,11,37,1) 52%, rgba(234,105,124,1) 100%);
  background: -moz-linear-gradient(225deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(186,11,37,1) 52%, rgba(234,105,124,1) 100%);
  background: linear-gradient(225deg, rgba(210,244,146,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(186,11,37,1) 52%, rgba(234,105,124,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.selected {
  background: -webkit-linear-gradient(-135deg, rgba(143,155,247,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(31,11,183,1) 52%, rgba(152,90,252,1) 100%);
  background: -moz-linear-gradient(225deg, rgba(143,155,247,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(31,11,183,1) 52%, rgba(152,90,252,1) 100%);
  background: linear-gradient(225deg, rgba(143,155,247,1) 0, rgba(210,244,146,1) 1%, rgba(249,179,197,1) 1%, rgba(31,11,183,1) 52%, rgba(152,90,252,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.deleted {
  background: -webkit-linear-gradient(-225deg, rgba(237,237,237,1) 0, rgba(29,30,28,1) 39%, rgba(140,140,140,1) 74%, rgba(29,30,28,1) 100%);
  background: -moz-linear-gradient(315deg, rgba(237,237,237,1) 0, rgba(29,30,28,1) 39%, rgba(140,140,140,1) 74%, rgba(29,30,28,1) 100%);
  background: linear-gradient(315deg, rgba(237,237,237,1) 0, rgba(29,30,28,1) 39%, rgba(140,140,140,1) 74%, rgba(29,30,28,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.issues {
  background: -webkit-linear-gradient(-145deg, rgba(255,0,0,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background: -moz-linear-gradient(235deg, rgba(255,0,0,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background: linear-gradient(235deg, rgba(255,0,0,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.noissues {
  background: -webkit-linear-gradient(-145deg, rgba(2,255,2,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background: -moz-linear-gradient(235deg, rgba(2,255,2,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background: linear-gradient(235deg, rgba(2,255,2,1) 0, rgba(42,114,47,1) 43%, rgba(42,114,47,1) 67%, rgba(55,229,66,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.block {
  background: -webkit-linear-gradient(45deg, rgba(247,225,145,1) 0, rgba(247,225,145,1) 1%, rgba(183,143,0,1) 39%, rgba(242,220,55,1) 100%);
  background: -moz-linear-gradient(45deg, rgba(247,225,145,1) 0, rgba(247,225,145,1) 1%, rgba(183,143,0,1) 39%, rgba(242,220,55,1) 100%);
  background: linear-gradient(45deg, rgba(247,225,145,1) 0, rgba(247,225,145,1) 1%, rgba(183,143,0,1) 39%, rgba(242,220,55,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

.pre {
  background: -webkit-linear-gradient(-145deg, rgba(6,61,170,1) 0, rgba(6,61,170,1) 1%, rgba(145,211,255,1) 22%, rgba(0,64,107,1) 63%, rgba(145,211,255,1) 100%);
  background: -moz-linear-gradient(235deg, rgba(6,61,170,1) 0, rgba(6,61,170,1) 1%, rgba(145,211,255,1) 22%, rgba(0,64,107,1) 63%, rgba(145,211,255,1) 100%);
  background: linear-gradient(235deg, rgba(6,61,170,1) 0, rgba(6,61,170,1) 1%, rgba(145,211,255,1) 22%, rgba(0,64,107,1) 63%, rgba(145,211,255,1) 100%);
  background-position: 50% 50%;
  -webkit-background-origin: padding-box;
  background-origin: padding-box;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  -webkit-background-size: auto auto;
  background-size: auto auto;
}

 /* width */
 ::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: blue;
  margin:3px;
}


.top-bar {
  width:100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height:10vh;
}

.main-section {
  margin-top:20px;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  height: 85vh;
  width:100vw;
}

.main-right {
  max-width: 175px;
  overflow-y: auto;
}

.bottom-bar {
  width:100%;
  height: 5vh;
}

.main-middle-left {
  width:0vw;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
}

.main-middle-right {
  width:100%;
  display:flex;
}

.main-middle {
  display:flex;
  flex-direction: row;
  flex:1;
  justify-content: space-evenly;
}

.container-middle {
  flex:1;
  display:flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.search-box {
  display: flex;
  flex-direction:column;
  color:#000;
  margin:10px;
  padding:10px;
}

.search-input {
  border: none;
  border-radius: 5px;
  padding:5px;
}

.search-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.search-box-form {
  display: flex;
  background-color:rgba(255,255,255,0.8);
}

.search-slot {
  max-width: 160px;
  margin:20px;
}

.no-results {
  margin:20px;
  font-size:20px;
  color:#000;
}

.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self:stretch;
  align-items: center;
}

.unavailable-hour {
  background-color: rgba(0,0,0,0.5);
}

.weekend td {
  background-color: rgba(200,0,0,0.4);
}

.weekend .unavailable-hour {
  background-color: rgba(200, 0, 0, 0.74) !important;
}

.part-load {
  background-color: rgba(78, 88, 223, 0.54);
}

.other-job-title {
  color:#000;
  font-size:20px;
}

.other-job {
  margin-top:20px;
  margin-bottom:20px;
  border-bottom: solid 2px gray;
}